import { useEffect, useState } from "react";
import { Stack, Link, Text, Box, VStack } from "@chakra-ui/react";
import { PhoneIcon, TimeIcon } from "@chakra-ui/icons";
import RoomIcon from '@mui/icons-material/Room';



function ContactText() {
    
    const [styles, setStyles] = useState({
        w:'40%',
        minW:'500px',
        h:'unset'
    });

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000)
                setStyles({
                    w:'40%',
                    minW:'500px',
                    h:'100%'
                });
            else
                setStyles({
                    w:'full',
                    minW:'unset',
                    h:'unset'
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <Box
            display='flex'
            w={styles.w}
            minW={styles.minW}
            h={styles.h}
            justifyContent='center'
            alignItems='center'
            marginBlock='20px'
        >
            <VStack
                w='350px'
                textAlign='left'
                whiteSpace='pre-wrap'
                spacing='10px'
                fontSize='20px'
                color='var(--secondary)'
            >
                {[
                    <Link
                        href='tel:+17863579768'
                        color='var(--secondary)'
                        _hover={{textDecor:'none', fontWeight:'bold'}}
                    >
                        <Box>
                            <PhoneIcon boxSize='16px' m='2px'/> <u>Contact Us!</u>{'\n'}
                            (786) 357-9768
                        </Box>
                    </Link>,
                    <Link
                        isExternal
                        href='https://www.google.com/maps?ll=25.708759,-80.364942&z=19&t=h&hl=en&gl=US&mapclient=embed&cid=8633760220027600191'
                        color='var(--secondary)'
                        _hover={{textDecor:'none', fontWeight:'bold'}}
                    >
                        <Box>
                            <RoomIcon style={{width:'20px', height:'20px'}}/> <u>We are located at:</u>{'\n'}
                            10481 SW 64th Street{'\n'}
                            Miami, FL 33173
                        </Box>
                    </Link>,
                    <>
                        <TimeIcon boxSize='16px' m='2px'/> <u>We are open on:</u>{'\n'}
                        Monday - Friday{'\t'}6:30 AM - 5:00 PM{'\n'}
                        & Saturday{'\t'}{'\t'}6:30 AM - 3:00 PM
                    </>
                ].map((item) => {
                    return <Text w='100%'>
                        {item}
                    </Text>
                })}
            </VStack>
        </Box>
    );
}

export default ContactText;