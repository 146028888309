import { HStack, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function Footer() {

    const year = (new Date).getFullYear();
    const [styles, setStyles] = useState({
        w:'60%',
        h:'100%'
    });

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000)
                setStyles({
                    w:'60%',
                    h:'100%'
                });
            else if(window.innerWidth >= 750)
                setStyles({
                    w:'full',
                    h:'600px'
                });
            else
                setStyles({
                    w:'full',
                    h:'460px'
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <HStack
            id='footer'
            aria-label='footer'
            w='full'
            h='100px'
            backgroundColor='white'
            justify='space-around'
        >
            <Text
                textAlign='center'
            >
                Copyright © {year} · <Link
                    color='black'
                    textDecor='none'
                    href='/'
                >
                    Capita&nbsp;Nursery&nbsp;&&nbsp;Landscaping&nbsp;
                </Link>&nbsp;· <Link
                    isExternal
                    color='black'
                    textDecor='none'
                    href='https://julianlopez6850.github.io'
                >
                    Julian&nbsp;Lopez&nbsp;
                </Link>&nbsp;·&nbsp;All&nbsp;Rights&nbsp;Reserved.
            </Text>
        </HStack>
    );
}

export default Footer;