import { Link } from "@chakra-ui/react";

function NavbarButton(props) {
    return (
        <Link
            id={`navbar-button-${props.text}`}
            aria-label={`navbar-button-${props.text}`}
            href={props.path}
            color='var(--primary)'
            textDecor='none'
            border='2px solid transparent'
            borderBottomColor={window.location.pathname === props.path ?
                'var(--primary)' :
                'transparent'
            }
            _hover={window.location.pathname !== props.path ? {
                color:'var(--secondary)'
            } : {cursor:'default'}}
        >
            {props.text.toUpperCase()}
        </Link>
    );
}

export default NavbarButton;