import { Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";

function PlantModal(props) {
    return (
        <Modal
            id='plant-modal'
            aria-label='plant-modal'
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            scrollBehavior='outside'
            allowPinchZoom={true}
        >
            <ModalOverlay/>
            <ModalContent
                color='white'
                bgColor='var(--primary)'
            >
                <ModalCloseButton/>
                <ModalHeader>
                    {props.plant.name}
                </ModalHeader>
                <ModalBody>
                    <Image
                        objectFit='cover'
                        h='calc(60vh)'
                        w='100%'
                        maxW='100%'
                        src={props.plant.image}
                        marginBlock='10px'
                    />
                    <Text textAlign='left' marginBlock='5px'>
                        Height: {props.plant.height}
                    </Text>
                    <Text textAlign='left' marginBlock='5px'>
                        Container Volume: {props.plant.containerVolume}
                    </Text>
                    <Text textAlign='left' marginBlock='5px'>
                        Price: {props.plant.price}
                    </Text>
                </ModalBody>
                <ModalFooter/>
            </ModalContent>
        </Modal>
    )
}

export default PlantModal;