import { useEffect, useState } from "react";
import { VStack, Grid } from "@chakra-ui/react";

import GalleryItem from "../Components/GalleryItem";
import plantList from '../Helpers/PlantList'

function Gallery() {
    
    const [gridColumns, setGridColumns] = useState(`repeat(4, 1fr)`);

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1660)
                setGridColumns(`repeat(4, 1fr)`);
            else if(window.innerWidth >= 1240)
                setGridColumns(`repeat(3, 1fr)`);
            else if(window.innerWidth >= 820)
                setGridColumns(`repeat(2, 1fr)`);
            else
                setGridColumns(`repeat(1, 1fr)`);
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <VStack
            id='gallery-page'
            aria-label='gallery-page'
            w='full'
            h='full'
            backgroundColor='var(--secondary)'
            textAlign='center'
        >
            <Grid
                h='min-content'
                m='20px'
                templateColumns={gridColumns}
                gap='20px'
            >
                {plantList.map((plant, index) => {
                    return (
                        <GalleryItem
                            key={`${index}-${plant.name}`}
                            plant={plant}
                        />
                    )
                })}
            </Grid>
        </VStack>
    );
}

export default Gallery;