import MiniLogo from '../Assets/MiniLogo.svg'
import Crucia from '../Assets/Plants/IMG_3500.jpg'
import PolloCarpo7Gal from '../Assets/Plants/IMG_3503.jpg'
import PolloCarpo3Gal from '../Assets/Plants/IMG_3504.jpg'

export default [
    { name: 'Clusia', containerVolume: '7 gallons', height: '4 feet', price: '$12.00', image:Crucia },
    { name: 'Podocarpus', containerVolume: '7 gallons', height: '3.5 feet', price: '$13.00', image:PolloCarpo7Gal },
    { name: 'Podocarpus', containerVolume: '3 gallons', height: '1.5 feet', price: '$5.00', image:PolloCarpo3Gal },
    { name: 'Placeholder', containerVolume: '---', height: '---', price: '---', image:MiniLogo },
    { name: 'Placeholder', containerVolume: '---', height: '---', price: '---', image:MiniLogo },
    { name: 'Placeholder', containerVolume: '---', height: '---', price: '---', image:MiniLogo },
    { name: 'Placeholder', containerVolume: '---', height: '---', price: '---', image:MiniLogo },
    { name: 'Placeholder', containerVolume: '---', height: '---', price: '---', image:MiniLogo },
]