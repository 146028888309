import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './Components/Navbar';
import ContactSection from './Components/ContactSection';
import Footer from './Components/Footer';

import Home from './Pages/Home';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
    return (
        <ChakraProvider>
            <Router>
                <Navbar/>
                <Routes>
                    <Route exact path="/" element={<><Home/><ContactSection/></>} />
                    <Route exact path="/gallery" element={<><Gallery/><ContactSection/></>} />
                    <Route exact path="/contact" element={<Contact/>} />
                </Routes>
                <Footer/>
            </Router>
        </ChakraProvider>
    );
}

export default App;