import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

function ContactMap() {
    const [styles, setStyles] = useState({
        w:'60%',
        h:'100%'
    });

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000)
                setStyles({
                    w:'60%',
                    h:'100%'
                });
            else if(window.innerWidth >= 750)
                setStyles({
                    w:'full',
                    h:'600px'
                });
            else
                setStyles({
                    w:'full',
                    h:'460px'
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <Box
            w={styles.w}
            h={styles.h}
        >
            <iframe
                id='google-maps'
                aria-label='Capita Landscaping Google Maps'
                title='Capita Landscaping'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d542.3901900953945!2d-80.36487392745128!3d25.708697989780926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c7002f082485%3A0x77d1472d2ea4bd3f!2sCapita%20Nursery%20%26%20Landscaping!5e1!3m2!1sen!2sus!4v1709503749151!5m2!1sen!2sus"
                width='100%'
                height='100%'
                style={{border:0}}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </Box>
    );
}

export default ContactMap;