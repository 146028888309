import { useEffect, useState } from "react";
import { Stack } from "@chakra-ui/react";
import ContactText from "./ContactText";
import ContactMap from "./ContactMap";

function ContactSection() {
    
    const [styles, setStyles] = useState({
        h:'500px',
        stackDir:'row',
        stackHeight:`${window.innerHeight - 200}px`
    });

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000)
                setStyles({
                    h:'500px',
                    stackDir:'row',
                    stackHeight:`${window.innerHeight - 200}px`
                });
            else if(window.innerWidth >= 750)
                setStyles({
                    h:'fit-content',
                    stackDir:'column',
                    stackHeight:`full`
                });
            else if(window.innerWidth >= 500)
                setStyles({
                    h:'fit-content',
                    stackDir:'column',
                    stackHeight:`full`
                });
            else
                setStyles({
                    h:'fit-content',
                    stackDir:'column',
                    stackHeight:`full`
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <Stack
            direction={styles.stackDir}
            id='contact-section'
            aria-label='contact-section'
            w='full'
            h={styles.h}
            backgroundColor='var(--primary)'
            justify='top'
            position='relative'
            spacing='0'
        >
            <ContactMap/>
            <ContactText/>
        </Stack>
    );
}

export default ContactSection;