import { useEffect, useState } from "react";
import { HStack, IconButton, Image, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import '../App.css'

import FullLogo from '../Assets/FullLogo.svg'
import NavbarButton from "./NavbarButton";
import { HamburgerIcon } from "@chakra-ui/icons";

function Navbar() {

    const [navbarHeight, setNavbarHeight] = useState('100px');
    const [navbarFontSize, setNavbarFontSize] = useState();
    const [navbarMenu, setNavbarMenu] = useState(false);

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 720)
                setNavbarMenu(false);
            else
                setNavbarMenu(true);

            if(window.innerWidth >= 1240) {
                setNavbarHeight('100px');
                setNavbarFontSize('20px');
            } else if(window.innerWidth >= 1000) {
                setNavbarHeight('100px');
                setNavbarFontSize('18px');
            } else if(window.innerWidth >= 820) {
                setNavbarHeight('80px');
                setNavbarFontSize('18px');
            } else {
                setNavbarHeight('60px');
                setNavbarFontSize('16px');
            }
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <HStack
            id='navbar'
            aria-label='navbar'
            h={navbarHeight}
            paddingInline='10%'
            justify='space-between'
            spacing='0'
        >
            <HStack id='logo'>
                <Link
                    href='/'
                >
                    <Image
                        h={navbarHeight}
                        src={FullLogo}
                    />
                </Link>
            </HStack>
            <HStack
                id='navbar-buttons'
                aria-label='navbar-buttons'
                fontSize={navbarFontSize}
                fontWeight='bold'
                spacing='40px'
            >
                {navbarMenu ?
                    <Menu
                        placement='bottom-end'
                    >
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            variant='outline'
                            colorScheme='var(--primary)'
                        />
                        <MenuList
                            display='flex'
                            flexDir='column'
                            w='fit-content'
                            minW='unset'
                            padding='8px'
                            gap='4px'
                            borderColor='var(--primary)'
                        >
                            <NavbarButton text='home' path='/'/>
                            <NavbarButton text='gallery' path='/gallery'/>
                            <NavbarButton text='contact' path='/contact'/>
                        </MenuList>
                    </Menu> :
                    <>
                        <NavbarButton text='home' path='/'/>
                        <NavbarButton text='gallery' path='/gallery'/>
                        <NavbarButton text='contact' path='/contact'/>
                    </>
                }
            </HStack>
        </HStack>
    );
}

export default Navbar;