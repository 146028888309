import { useEffect, useState } from "react";
import {
    VStack,
    HStack,
    Text,
    Image,
    Link,
    Grid,
    Stack
} from "@chakra-ui/react";

import CapitaLandscapingImage from '../Assets/CapitaLandscapingImage.jpg'

import GalleryItem from "../Components/GalleryItem";
import plantList from '../Helpers/PlantList'

function Home() {
    
    const [styles, setStyles] = useState('');
    const [gridColumns, setGridColumns] = useState(`repeat(2, 1fr)`);

    useEffect(() => {
        const windowListener = () => {

            if(window.innerWidth >= 1500)
                setGridColumns(`repeat(2, 1fr)`);
            else if(window.innerWidth >= 1100)
                setGridColumns(`repeat(3, 1fr)`);
            else if(window.innerWidth >= 720)
                setGridColumns(`repeat(2, 1fr)`);
            else
                setGridColumns(`repeat(1, 1fr)`);

            if(window.innerWidth >= 1500)
                setStyles({
                    header:'WELCOME TO CAPITA NURSERY & LANDSCAPING',
                    headerFontSize:'24px',
                    fontSize:'16px',
                    lineHeight:'26px',
                    stackDir:'row'
                });
            else if(window.innerWidth >= 1100)
                setStyles({
                    header:'WELCOME TO CAPITA NURSERY & LANDSCAPING',
                    headerFontSize:'22px',
                    fontSize:'16px',
                    lineHeight:'25px',
                    stackDir:'column'
                });
            else if(window.innerWidth >= 720)
                setStyles({
                    header:'WELCOME TO CAPITA NURSERY & LANDSCAPING',
                    headerFontSize:'20px',
                    fontSize:'14px',
                    lineHeight:'24px',
                    stackDir:'column'
                });
            else if(window.innerWidth >= 500)
                setStyles({
                    header:`WELCOME TO\nCAPITA NURSERY & LANDSCAPING`,
                    headerFontSize:'20px',
                    fontSize:'12px',
                    lineHeight:'22px',
                    stackDir:'column'
                });
            else if(window.innerWidth >= 400)
                setStyles({
                    header:`WELCOME TO\nCAPITA NURSERY & LANDSCAPING`,
                    headerFontSize:'18px',
                    fontSize:'12px',
                    lineHeight:'22px',
                    stackDir:'column'
                });
            else
                setStyles({
                    header:`WELCOME TO\nCAPITA NURSERY & LANDSCAPING`,
                    headerFontSize:'16px',
                    fontSize:'12px',
                    lineHeight:'22px',
                    stackDir:'column'
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <VStack
            id='home-page'
            aria-label='home-page'
            w='full'
            minH='calc(100vh)'
            h='100%'
            backgroundColor='white'
            alignItems='top'
        >
            <Image
                w='100%'
                minH='300px'
                fit='cover'
                src={CapitaLandscapingImage}
            />
            <VStack
                h='fit-content'
                marginBlock='20px'
            >
                <Text
                    fontSize={styles.headerFontSize}
                    fontWeight='bold'
                    color='var(--secondary)'
                    textAlign='center'
                    whiteSpace='pre-wrap'
                >
                    {styles.header}
                </Text>
                <Stack
                    direction={styles.stackDir}
                    w='90%'
                    h='full'
                    spacing='40px'
                    marginBlock='20px'
                    justifyContent='center'
                    alignItems='center'
                >
                    <VStack
                        w='100%'
                        h='fit-content'
                    >
                        <Text
                            textAlign='justify'
                            whiteSpace='pre-wrap'
                            lineHeight={styles.lineHeight}
                            fontSize={styles.fontSize}
                        >
                            <span
                                style={{
                                    color:'var(--primary)',
                                    fontWeight:'bold'
                                }}
                            >
                                Capita Nursery & Landscaping
                            </span>{' '}
                            is a family-owned and operated business. We speak both English and Spanish, and are available to take your call at any time of day.
                            With over 10 years in business, we are prepared to help you find the perfect plants for your garden or outdoor landscape. We understand the importance
                            of growing a wide-variety of plant species in our nursery, and we handle our plants with the utmost care to ensure that they grow healthy and strong and
                            make certain that our customers receive the best finest plants found in the South Florida region. We treat our customers with the same care as our plants, 
                            guaranteeing that all customers leave our business happy, knowing their garden or landscape will look great with their new plants, for years to come!
                            {'\n\n'}
                            With a 2.5-acre plot of land holding a wide variety of container plants, native to South Florida and beyond, we expect to have the plants you are looking for on-site!
                            We are always looking to diversify our nursery by finding new varieties of plants that our customers would love to own.
                            Feel free to take a look at our <Link href='/gallery'>gallery</Link> of plants to search for any plants that pique your interest.
                            We are hopeful and confident that you will find plants that you love. <Link href='tel:+17863579768'>Give us a call</Link> right away
                            if you would like to learn more about our business or if you have found a plant that is just right for your landscaping ideas! 
                            Our business is open 6 days a week and we even offer deliveries right to your home or business for substantial orders.
                        </Text>
                    </VStack>
                    <VStack
                        w='100%'
                        minW={styles.miniGalleryMinW}
                        h='fit-content'
                        justify='space-between'
                        bgColor='var(--secondary)'
                        textAlign='center'
                    >
                        <Grid
                            h={styles.miniGalleryH}
                            marginTop='20px'
                            templateColumns={gridColumns}
                        >
                            {plantList.slice(0,gridColumns.charAt(7)).map((plant, index) => {
                                return (
                                    <GalleryItem
                                        key={`${index}-${plant.name}`}
                                        plant={plant}
                                        itemHeight='340px'
                                        imageHeight='160px'
                                        itemWidth='280px'
                                        fontSize='12px'
                                    />
                                )
                            })}
                        </Grid>
                        <Link
                            href='/gallery'
                            fontSize={styles.fontSize}
                            fontWeight='bold'
                            textDecor='underline'
                            color='var(--primary)'
                            marginBottom='20px'
                        >
                            Want to See More Plants? Check out our Gallery!
                        </Link>
                    </VStack>
                </Stack>
            </VStack>
        </VStack>
    );
}

export default Home;