import { useEffect, useState } from "react";
import { GridItem, Image, Text, useDisclosure } from "@chakra-ui/react";

import PlantModal from "./PlantModal";


function GalleryItem(props) {

    const {
        isOpen: isOpenPlantModal, 
        onOpen: onOpenPlantModal, 
        onClose: onClosePlantModal, 
    } = useDisclosure();

    const [ itemWidth, setItemWidth ] = useState('345px');
    const [ itemHeight, setItemHeight ] = useState('500px');
    const [ imageHeight, setImageHeight ] = useState('300px');
    const [ fontSize, setFontSize ] = useState('16pzx');

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000) {
                setItemWidth('345px');
                setItemHeight('500px');
                setImageHeight('300px');
                setFontSize('16px');
            } else {
                setItemWidth('300px');
                setItemHeight('420px');
                setImageHeight('240px');
                setFontSize('12px');
            }
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return <>
        <GridItem
            id='gallery-item'
            aria-label='gallery-item'
            rowSpan={1}
            colSpan={1}
            h={props.itemHeight || itemHeight}
            w={props.itemWidth || itemWidth}
            m='10px'
            p='20px 60px'
            _hover={{
                m:'0px 20px 20px 0px'
            }}
            bg='white'
            color='var(--primary)'
            onClick={() => {
                console.log(props.plant.name)
                onOpenPlantModal();
            }}
            fontSize={props.fontSize || fontSize}
            cursor='pointer'
        >
            <Text fontSize='16px' fontWeight='bold' marginBlock='0px'>
                {props.plant.name}
            </Text>
            <Image
                objectFit='cover'
                h={props.imageHeight || imageHeight}
                w='100%'
                maxW='100%'
                src={props.plant.image}
                marginBlock='10px'
            />
            <Text textAlign='left' marginBlock='5px'>
                Height: {props.plant.height}
            </Text>
            <Text textAlign='left' marginBlock='5px'>
                Container Volume: {props.plant.containerVolume}
            </Text>
            <Text textAlign='left' marginBlock='5px'>
                Price: {props.plant.price}
            </Text>
        </GridItem>
        <PlantModal
            isOpen={isOpenPlantModal}
            onOpen={onOpenPlantModal}
            onClose={onClosePlantModal}
            plant={props.plant}
        />
    </>
}

export default GalleryItem;