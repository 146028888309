import { useEffect, useState } from "react";
import { Stack } from "@chakra-ui/react";
import ContactText from "../Components/ContactText";
import ContactMap from "../Components/ContactMap";

function Contact() {
    
    const [styles, setStyles] = useState({
        stackDir:'row',
        stackHeight:`${window.innerHeight - 200}px`,
        minStackHeight:'400px',
        boxText: {
            w:'40%',
            minW:'500px',
            h:'unset',
            minH:'unset'
        },
        boxMap: {
            w:'60%',
            h:'100%'
        }
    });

    useEffect(() => {
        const windowListener = () => {
            if(window.innerWidth >= 1000)
                setStyles({
                    stackDir:'row',
                    stackHeight:`${window.innerHeight - 200}px`,
                    minStackHeight:'unset',
                    boxText: {
                        w:'40%',
                        minW:'500px',
                        h:'100%'
                    },
                    boxMap: {
                        w:'60%',
                        h:'100%'
                    }
                });
            else if(window.innerWidth >= 820)
                setStyles({stackDir:'column',
                    stackHeight:`full`,
                    minStackHeight:`${window.innerHeight - 200}px`,
                    boxText: {
                        w:'full',
                        minW:'unset',
                        h:'unset'
                    },
                    boxMap: {
                        w:'full',
                        h:'600px'
                    }
                });
            else if(window.innerWidth >= 750)
                setStyles({stackDir:'column',
                    stackHeight:`full`,
                    minStackHeight:`${window.innerHeight - 180}px`,
                    boxText: {
                        w:'full',
                        minW:'unset',
                        h:'unset'
                    },
                    boxMap: {
                        w:'full',
                        h:'600px'
                    }
                });
            else
                setStyles({
                    stackDir:'column',
                    stackHeight:`full`,
                    minStackHeight:`${window.innerHeight - 160}px`,
                    boxText: {
                        w:'full',
                        minW:'unset',
                        h:'unset'
                    },
                    boxMap: {
                        w:'full',
                        h:'400px'
                    }
                });
        };
        windowListener();
        window.addEventListener('resize', windowListener);
        return () => window.removeEventListener('resize', windowListener);
    }, [])

    return (
        <Stack
            id='contact-page'
            aria-label='contact-page'
            direction={styles.stackDir}
            w='full'
            h={styles.stackHeight}
            minH={styles.minStackHeight}
            backgroundColor='var(--primary)'
            justify='space-between'
            position='relative'
            spacing='0'
        >
            <ContactText/>
            <ContactMap/>
        </Stack>
    );
}

export default Contact;